<template>
  <span>
    <span class="btn btn-light" :class="buttonClass" @click="showCreateAModal">
      <font-awesome-icon icon="plus-circle"/> {{$t('activities.create.title')}}
    </span>

    <!-- Create Activity modal template -->
    <b-modal ref="create-activity" @hide="hideCreateAModal" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateAModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-tp-title">
            {{ $t('activities.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-s-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="create-s-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-s-name-label">
              {{ $t('activities.create.name_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-input
              qid="create-s-form-name"
              type="text"
              v-model="sForm.name"
              :maxLength="255"
              required
              :placeholder="$t('activities.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label qid="create-tp-countries-label">
              {{ $t('maintenance.third_parties.create.country_label') }}
            </label>
            <multiselect
                v-model="selectedCountries"
                :options="countries"
                :multiple="true"
                :taggable="false"
                track-by="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('activities.create.countries_placeholder')"
                label="name"
                qid="create-tp-countries-select"
            >
              <template slot="tag" slot-scope="props">
                <img v-bind:src="props.option.flag" height="13" class="align-baseline" />
                <span class="mr-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <img v-bind:src="props.option.flag" height="13" class="align-baseline" />
                <span class="mr-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group>
            <label qid="create-s-users-label">
              {{ $t('activities.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('activities.create.owners_placeholder')"
                label="name"
                @remove="removeUserFromDropdown"
                @search-change="loadUsers"
                @select="addUser"
                qid="create-s-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span></span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
              <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                <div class="row">
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="star"
                      :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                      class="cursor-pointer"
                      @click="setOwner(index)"
                    />
                  </div>
                  <div class="col-sm-10">
                    <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                    <span class="ml-2">{{ user.name }}</span>
                  </div>
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="times"
                      class="cursor-pointer"
                      @click="removeUser(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span class="text-light">
              {{ $t('activities.create.owners_tip') }}
            </span>
          </b-form-group>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideCreateAModal"
                            qid="create-s-cancel-button"
                  >
                    {{ $t('activities.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            @click="formSubmit"
                            :disabled="buttonDisabled"
                            qid="create-s-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('activities.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ActivityCreateForm',
    data: function() {
      return {
        sForm: {
          name: '',
          client:''
        },
        countries: [],
        selectedCountries: [],
        error: '',
        users: [],
        selectedUsers: [],
        buttonDisabled: false,
        editParam: false,
        modalShown: false,
      }
    },
    props: {
      buttonClass: {},
      process: {}
    },
    components: {
      Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      // getClient: function () {
      // },
      process: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showCreateAModal(self.process)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      addUser(selectedItem, id) {
        // If user is the first to be added to the list it will be auto-marked as owner
        if (this.selectedUsers.length == 0) {
          selectedItem.owner = true;
        }else{
          selectedItem.owner = false;
        }
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        let files = document.querySelector('#file');
        let selectedOwner = false;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // Append list of users to FormData
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            formData.append("users[]", this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              formData.append("owner", this.selectedUsers[i].id);
            }
          }
        } else {
          selectedOwner = true;
        }

        // Append list of countries to FormData
        if (this.selectedCountries && this.selectedCountries.length) {
          for (var i = 0; i < this.selectedCountries.length; i++) {
            formData.append("countries[]", this.selectedCountries[i].name);
          }
        }

        formData.append("name", this.sForm.name);
        formData.append("client", this.getClient.slug);

        if (selectedOwner && this.sForm.name && this.sForm.name.length) {
          piincHttp.post('/activities', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('activities.create.success'))
            self.buttonDisabled = false;

            if (self.process && self.process.id) {
              self.addActivity(response.data)
            } else {
              self.$emit('reloadContent');
            }

            self.hideCreateAModal()
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              // Print whole array of actual errors beside message
              self.error = self.error + '\n\n' + JSON.stringify(error.data.errors);
            }
          })
        } else {
          this.error = ''
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }
          if (!this.sForm.name) {
            this.error += this.$t('system.name_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      addActivity(object) {
        let self = this;
        let formData = new FormData();

        if (this.process && this.process.slug) {
          formData.append("process", this.process.slug);
        }

        formData.append("activity", object.slug);

        piincHttp.post('processes/' + this.process.slug + '/activities', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$emit('reloadContent');
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.activity) {
              self.error += ' ' + error.data.errors.activity[0]
            }
          }
        })
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadCountries() {
        let self = this;
        piincHttp.get('countries-with-flag').then(function(response) {
          self.countries = response.data
        }, function() {});
      },
      showCreateAModal() {
        let self = this;
        this.loadUsers()
        this.loadCountries()
        this.error = ''
        this.$refs['create-activity'].show()
      },
      hideCreateAModal() {
        this.sForm.name = ''
        this.selectedUsers = []
        this.selectedCountries = []
        this.sForm.client = ''
        this.$refs['create-activity'].hide()
      },
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.editParam = this.edit;
        let self = this;
        if (this.object && this.object.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    }
  }
</script>
